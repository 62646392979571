import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Flickity from 'react-flickity-component'
import debounce from 'lodash.debounce'
import { PriceBox } from '../PriceBox/PriceBox'

/*
 * Bronson CompareAndSelect component.
 *
 * Generated React component with manual logic and adaption of Bronson workarounds to Flickity.
 */
export function CompareAndSelect({
  testId,
  inputGroup,
  buttonVariant = false,
  radioButtonSection,
  deselectable,
  defaultSelected,
  children,
  onChange,
  ...otherProps /* in <div> tag */
}) {
  const [flickityRef, setFlickityRef] = useState(null)

  const [selected, setSelected] = useState(defaultSelected)

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production' && deselectable && radioButtonSection) {
      throw new Error('A radio button group cannot be deselectable.')
    }
  }, [deselectable, radioButtonSection])

  const updateSelected = (newSelected) => {
    if (newSelected === selected) {
      if (deselectable && !radioButtonSection) {
        // eslint-disable-next-line no-param-reassign
        newSelected = null
      }
    }
    setSelected(newSelected)
    if (onChange) {
      onChange(newSelected)
    }
    flickityRef && flickityRef.resize()
  }

  useEffect(() => {
    if (flickityRef) {
      const resizeFn = flickityRef.resize.bind(flickityRef)
      flickityRef.resize = () => {
        flickityRef.element.classList.remove('flickity-resize')
        resizeFn()
        requestAnimationFrame(() => flickityRef.element.classList.add('flickity-resize'))
      }
      flickityRef.on('select', toggleSliderControls)

      flickityRef.on('ready', debounce(flickityRef.resize, 100)())
    }
  }, [flickityRef, toggleSliderControls])

  const toggleSliderControls = useCallback(() => {
    if (flickityRef) {
      const size = flickityRef.size.width
      const { slideableWidth } = flickityRef
      const slideable = slideableWidth > size

      const pageDotsHTMLCollection = flickityRef.element.getElementsByClassName('flickity-page-dots')
      const flickityButtonsHTMLCollection = flickityRef.element.getElementsByClassName('flickity-prev-next-button')

      /**
       * This fixes a bug where Flickity is unable to reassigne the draggable behavior when resizing from a big to small
       * viewport when `groupCells` or `contain` options are set.
       * The fix only kicks in when the `draggable` option {@link https://flickity.metafizzy.co/options.html#draggable} in Flickity was set.
       * @see https://github.com/metafizzy/flickity/issues/960
       */
      if (flickityRef.options.draggable) {
        flickityRef.isDraggable = slideable
        flickityRef.element.classList.toggle('is-draggable', slideable)
      }

      Array.from(pageDotsHTMLCollection).forEach((pageDots) => {
        pageDots.classList.toggle('is-slideable', slideable)
      })

      Array.from(flickityButtonsHTMLCollection).forEach((btn) => {
        btn.classList.toggle('is-slideable', slideable)
      })
    }
  }, [flickityRef])

  const flickityOptions = {
    cellSelector: '.js-compare-and-select__item',
    cellAlign: 'center',
    groupCells: '100%',
    initialIndex: defaultSelected,
  }

  const flickityClassNameList = classNames({
    'c-compare-and-select-container__inner ': true,
    'js-compare-and-select ': true,
    'is-initialized': true, // Bronson
  }).trim()

  // generated main result
  return (
    <div {...otherProps} data-testid={testId} className="c-compare-and-select-container">
      <Flickity
        elementType="div"
        className={flickityClassNameList}
        flickityRef={(c) => setFlickityRef(c)}
        options={flickityOptions}
      >
        {
          /* Use 'CompareAndSelect.Item' component. */

          React.Children.map(children, (child, index) => {
            if (!child) {
              return null
            }
            return React.cloneElement(child, {
              selected: selected === index,
              updateSelected,
              index,
              buttonVariant,
              radioButtonSection,
              inputGroup,
            })
          })
        }
      </Flickity>
    </div>
  )
}

CompareAndSelect.propTypes = {
  testId: PropTypes.string, // Added for data-testid attribute.
  inputGroup: PropTypes.string.isRequired, // Bronson template: 'input-group'.
  buttonVariant: PropTypes.bool, // Bronson template: 'is-button-variant'.
  radioButtonSection: PropTypes.bool, // Bronson template: 'has-radio-button-section'.
  children: PropTypes.node, // Bronson template: 'compare-and-select'. Use 'CompareAndSelect.Item' component.
  /* (props) => {
    if (React.Children.toArray(props.children).some((child) => child.type !== CompareAndSelect.Item)) {
      return new Error("'CompareAndSelect' children should be of type 'CompareAndSelect.Item'")
    }
  }, */
  deselectable: PropTypes.bool, // if set 'true' items can be deselected, default: false
  defaultSelected: PropTypes.number, // the default selected item by index
  onChange: PropTypes.func, // onChange callback when selection changes
}

export function CompareAndSelectItem({
  selected,
  updateSelected,
  index,
  buttonVariant,
  radioButtonSection,
  inputGroup,

  label,
  icon,
  indicatorTitle,
  title,
  truncateTitle,
  className,

  price,
  value,
  pricingHeader,
  valueSuffix,
  ariaLabelDiscountValue,
  discountValue,
  ariaLabelDiscountOldPrice,
  discountOldPrice,
  ariaLabelPrice,
  pricingAdditionalInfo,
  infoIcon,

  children: itemChildren,

  description,
  descriptionTitle,
  descriptionLinkHref,
  descriptionLinkLabel,
  descriptionInfoIcon,
  descriptionClassName,

  buttonLabel,
  disableButton,
  checkbox,

  legal,
  legalTitle,

  inputAttributes,
  radioDescription,
  radioValue,
  radioInfoIcon,
  ...otherProps /* in <div> tag */
}) {
  const priceValue = value || price
  const priceHeader = (
    <>
      {valueSuffix || pricingHeader}
      {infoIcon}
    </>
  )

  const itemClassNameList = classNames('c-compare-and-select__item', 'js-compare-and-select__item', className).trim()

  // generated
  const inputClassNameList = classNames('c-compare-and-select__hidden-input ', {
    'c-radio__input ': radioButtonSection,
  }).trim()

  // generated
  function renderUnlessButtonVariant() {
    if (!buttonVariant) {
      return (
        <input
          className={inputClassNameList}
          type="radio"
          name={inputGroup}
          id={`${inputGroup}-${index}`}
          checked={selected}
          readOnly
          {...inputAttributes}
        />
      )
    }
    return null
  }

  // generated
  const divClassNameList = classNames('c-compare-and-select ', {
    'c-compare-and-select--selected ': buttonVariant && selected,
    'c-radio__label-container ': radioButtonSection,
  }).trim()

  // generated
  function renderIfLabel() {
    if (label) {
      return <div className="c-compare-and-select__label">{label}</div>
    }
    return null
  }

  // generated
  const iClassNameList = classNames({
    'c-compare-and-select__icon ': icon,
    'c-icon ': icon,
    [`c-icon--[${icon}] `]: icon,
  }).trim()

  // generated
  function renderIfIcon() {
    if (icon) {
      return <i className={iClassNameList} aria-hidden="true" role="img" />
    }
    return null
  }

  // generated
  function renderIfValue() {
    if (priceValue) {
      return (
        <div className="c-compare-and-select__pricing">
          <PriceBox
            header={priceHeader}
            ariaLabelDiscountValue={ariaLabelDiscountValue || 'Discount:'}
            discountValue={discountValue}
            ariaLabelDiscountOldPrice={ariaLabelDiscountOldPrice || 'Old Price:'}
            discountOldPrice={discountOldPrice}
            ariaLabelPrice={ariaLabelPrice || 'Discount Price:'}
            price={priceValue}
            additionalInfo={pricingAdditionalInfo}
          />
        </div>
      )
    }
    return null
  }

  // generated
  function renderIfChildren() {
    if (itemChildren) {
      return (
        <ul className="c-compare-and-select__list c-icon-list c-icon-list--small">
          {itemChildren /* Use 'SelectAndCompare.CompareAndSelect.IconListItem' component. */}
        </ul>
      )
    }
    return null
  }

  function renderIfLinkInDescription() {
    if (descriptionLinkHref && descriptionLinkLabel) {
      return <a href={descriptionLinkHref}>{descriptionLinkLabel}</a>
    }
    return null
  }

  const descriptionClassNameList = classNames('c-compare-and-select__description', descriptionClassName).trim()

  // generated
  function renderIfDescription() {
    if (description) {
      return (
        <p className={descriptionClassNameList}>
          {descriptionTitle && (
            <>
              <strong>{descriptionTitle}</strong>:{' '}
            </>
          )}
          {description}
          {renderIfLinkInDescription()}
          {descriptionInfoIcon}
        </p>
      )
    }
    return null
  }

  // generated
  function renderIfButton() {
    if (buttonLabel) {
      return (
        <>
          {buttonVariant ? (
            <>
              <button
                onClick={() => updateSelected(index)}
                className="c-compare-and-select__button c-btn"
                type="button"
                disabled={disableButton}
              >
                {buttonLabel}
              </button>
            </>
          ) : (
            <>
              <label
                className="c-compare-and-select__button c-btn"
                htmlFor={`${inputGroup}-${index}`}
                onClick={() => updateSelected(index)}
                disabled={disableButton}
              >
                {buttonLabel}
              </label>
            </>
          )}
        </>
      )
    }
    return null
  }

  // generated
  function renderIfCheckbox() {
    if (checkbox) {
      return <div className="c-compare-and-select__checkbox">{checkbox}</div>
    }
    return null
  }

  // generated
  function renderIfLegal() {
    if (legal) {
      return (
        <p className="c-compare-and-select__legal">
          {legalTitle && (
            <>
              <strong>{legalTitle}</strong>:{' '}
            </>
          )}
          {legal}
        </p>
      )
    }
    return null
  }

  // generated
  function renderIfRadioButtonSection() {
    if (radioButtonSection) {
      return (
        <label
          className="c-radio c-compare-and-select__radio"
          htmlFor={`${inputGroup}-${index}`}
          onClick={(e) => e.target.className.search('info-icon') === -1 && updateSelected(index)}
        >
          <div className="c-radio__label c-compare-and-select__radio-label" checked={buttonVariant && selected}>
            {radioValue}
            {radioInfoIcon}
            <div className="c-compare-and-select__radio-description">{radioDescription}</div>
          </div>
        </label>
      )
    }
    return null
  }

  function renderIfIndicatorTitle() {
    if (indicatorTitle) {
      return (
        <h3 className="c-compare-and-select__title-indicator-text" title={indicatorTitle}>
          {indicatorTitle}
        </h3>
      )
    }
    return null
  }

  const titleTextClassNameList = classNames('c-compare-and-select__title-text', {
    'u-text-truncate': truncateTitle,
  }).trim()

  // generated main result
  return (
    <div {...otherProps} className={itemClassNameList}>
      {renderUnlessButtonVariant()}
      <div className={divClassNameList}>
        {renderIfLabel()}
        <header className="c-compare-and-select__title">
          {renderIfIcon()}
          {renderIfIndicatorTitle()}
          <h4 className={titleTextClassNameList} title={title}>
            {title}
          </h4>
        </header>
        {renderIfValue() /* PriceBox */}
        {renderIfChildren() /* IconList */}
        {renderIfDescription()}
        {renderIfButton()}
        {renderIfCheckbox()}
        {renderIfLegal()}
        {renderIfRadioButtonSection()}
      </div>
    </div>
  )
}

CompareAndSelect.Item = CompareAndSelectItem
CompareAndSelect.Item.displayName = 'CompareAndSelect.Item'

CompareAndSelect.Item.propTypes = {
  label: PropTypes.string, // Bronson template: 'label'.
  icon: PropTypes.string, // Bronson template: 'icon'.
  indicatorTitle: PropTypes.string, // Bronson template: 'indicator-title'.
  title: PropTypes.string, // Bronson template: 'title'.
  truncateTitle: PropTypes.bool, // truncates title, default: false
  className: PropTypes.string, // additional CSS classes on main element 'c-compare-and-select__item'

  price: PropTypes.node, // Bronson template: 'pricing.price', see PriceBox.price
  value: PropTypes.string, // old < 7.32.0, mapped to 'price'
  pricingHeader: PropTypes.node, // Bronson template: 'pricing.header', see PriceBox.header
  valueSuffix: PropTypes.node, // old < 7.32.0, mapped to 'pricingHeader'
  ariaLabelDiscountValue: PropTypes.string, // see PriceBox.ariaLabelDiscountValue
  discountValue: PropTypes.node, // Bronson template: 'pricing.discount.value', see PriceBox.discountValue
  ariaLabelDiscountOldPrice: PropTypes.string, // see PriceBox.ariaLabelDiscountOldPrice
  discountOldPrice: PropTypes.node, // Bronson template: 'pricing.discount.price', see PriceBox.discountOldPrice
  ariaLabelPrice: PropTypes.string, // see PriceBox.ariaLabelPrice
  pricingAdditionalInfo: PropTypes.node, // Bronson template: 'pricing.discount.price', see PriceBox.additionalInfo
  infoIcon: PropTypes.node, // still used for backward compatibility

  children: PropTypes.node, // Bronson template: 'icon-list'. Use 'SelectAndCompare.CompareAndSelect.IconList' component.

  description: PropTypes.node, // Bronson template: 'description'.
  descriptionTitle: PropTypes.string,
  descriptionLinkHref: PropTypes.string, // Bronson template: 'link.href'
  descriptionLinkLabel: PropTypes.string, // Bronson template: 'link.label'
  descriptionInfoIcon: PropTypes.element, // Bronson template: 'info-icon'. Use component InfoIcon
  descriptionClassName: PropTypes.string, // adds custom CSS classes to the description element 'c-compare-and-select__description'

  buttonLabel: PropTypes.string,
  disableButton: PropTypes.bool,
  checkbox: PropTypes.node, // Bronson template: 'checkbox'.

  legal: PropTypes.node,
  legalTitle: PropTypes.string,

  inputAttributes: PropTypes.object, // Bronson template: 'input-attribute'.
  radioDescription: PropTypes.node, // Bronson template: 'radio-description'.
  radioValue: PropTypes.string, // Bronson template: 'radio-value'.
  radioInfoIcon: PropTypes.node, // Bronson template: 'info-icon'.
}

/*
 * Bronson IconListItem component (nested).
 *
 * Generated React component. Do not modify.
 */
function IconListItem({ icon, iconDescription, children, ...otherProps /* in <li> tag */ }) {
  // generated
  const iClassNameList = classNames('c-icon-list__icon ', {
    'c-icon ': true,
    [`c-icon--[${icon}] `]: icon,
  }).trim()

  // generated main result
  return (
    <li {...otherProps}>
      <i className={iClassNameList} aria-hidden="true" role="img" />
      <span className="u-visually-hidden">{`${iconDescription}: `}</span>
      {children}
    </li>
  )
}

IconListItem.propTypes = {
  icon: PropTypes.string.isRequired, // Bronson template: 'icon'.
  iconDescription: PropTypes.string.isRequired, // Bronson template: 'icon-description'.
  children: PropTypes.node, // Bronson template: 'text'.
}

IconListItem.displayName = 'CompareAndSelect.IconListItem'
CompareAndSelect.IconListItem = IconListItem
