import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link } from '../Link/Link'

/*
 * Bronson ItemTeaser component.
 *
 * Generated React component. Do not modify.
 */
export function ItemTeaser({
  className,
  iconClassName,
  icon,
  iconDescription,
  title,
  children,
  linkLabel,
  linkProps,
  testId,
  small,
  inline,
  inlineSmallScreen,
  block,
  blockSmallScreen,
  ...otherProps /* in <article> tag */
}) {
  // generated
  const articleClassNameList = classNames(
    {
      'c-item-teaser ': true,
      // main class modifier convenience prop extension
      'c-item-teaser--small': small, // Convenience prop from Bronson variants.
      'c-item-teaser--inline': inline, // Convenience prop from Bronson variants.
      'c-item-teaser--block': block, // Convenience prop from Bronson variants.
      'c-item-teaser--inline@small-screen': inlineSmallScreen, // Convenience prop from Bronson variants.
      'c-item-teaser--block@small-screen': blockSmallScreen, // Convenience prop from Bronson variants.
    },
    className
  ).trim()

  // generated
  const iClassNameList = classNames(
    {
      'c-item-teaser__icon ': true,
      'c-icon ': true,
      [`c-icon--[${icon}] `]: true,
    },
    iconClassName
  ).trim()

  // generated
  function renderIfItemTeaserLinkLabel() {
    if (linkLabel) {
      return (
        <div className="c-item-teaser__link">
          <Link icon="semantic-forward" iconClassName="c-icon--small" {...linkProps}>
            {linkLabel}
          </Link>
        </div>
      )
    }
    return null
  }

  // generated main result
  return (
    <article {...otherProps} data-testid={testId} className={articleClassNameList}>
      <i className={iClassNameList} aria-hidden="true" role="img" />
      <span className="c-item-teaser__icon-description u-visually-hidden">{iconDescription}</span>
      <div className="c-item-teaser__body">
        <h5 className="c-item-teaser__title">{title}</h5>
        <p className="c-item-teaser__text">{children}</p>
        {renderIfItemTeaserLinkLabel()}
      </div>
    </article>
  )
}

ItemTeaser.propTypes = {
  className: PropTypes.string, // Bronson template: 'item-teaser-modifier'.
  iconClassName: PropTypes.string, // prop to set custom css classes to 'item-teaser-icon'
  icon: PropTypes.string, // Bronson template: 'item-teaser-icon'.
  iconDescription: PropTypes.string, // Bronson template: 'item-teaser-icon-description'.
  title: PropTypes.string, // Bronson template: 'item-teaser-title'.
  children: PropTypes.node, // Bronson template: 'item-teaser-text'.
  linkLabel: PropTypes.string, // Bronson template: 'item-teaser-link-label'.
  linkProps: PropTypes.exact(Link.propTypes),
  testId: PropTypes.string, // Added for data-testid attribute.
  /* Convenience props */
  small: PropTypes.bool, // Convenience prop for c-item-teaser--small (Bronson template: 'item-teaser-modifier').
  inline: PropTypes.bool, // Convenience prop for c-item-teaser--inline (Bronson template: 'item-teaser-modifier').
  inlineSmallScreen: PropTypes.bool, // Convenience prop for c-item-teaser--inline@small-screen (Bronson template: 'item-teaser-modifier').
  block: PropTypes.bool, // Convenience prop for c-item-teaser--block (Bronson template: 'item-teaser-modifier').
  blockSmallScreen: PropTypes.bool, // Convenience prop for c-item-teaser--block@small-screen (Bronson template: 'item-teaser-modifier').
}
