import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

/**
 * The SectionHeading component shows headings in a consistent manner.
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-section-headings--section-headings.html
 *
 * note: icon is deprecated @see https://jira.platform.vwfs.io/browse/BRON-4768
 *
 * @param {string} level - headings level
 * @param {string} children - headings title
 * @param {boolean} [primary] - primary section heading
 * @param {string} [subtitle] - optional subtitle <p> element
 * @param {string} [superscript] - optional inline superscript <sup> element
 * @param {boolean} [smallSpacing] - decrease spacing
 * @param {boolean} [center] - center heading horizontally
 * @param {boolean} [alternative] - alternative secondary title, use with {@Link titleSecondary}
 * @param {string} [titleSecondary] - secondary title
 * @param {string} [className] - additional classes on main header element
 * @param {string} [testId] - data-testid attribute on main header element
 * @param {Object} [otherProps] - other props to be passed to main element
 * @return {JSX.Element} - SectionHeading component
 *
 */
export function SectionHeading({
  level,
  children,
  primary,
  subtitle,
  superscript,
  smallSpacing,
  center,
  alternative,
  titleSecondary,
  className,
  testId,
  ...otherProps /* in <header> tag */
}) {
  const headerClassNameList = classNames(
    'c-section-heading',
    {
      'c-section-heading--center ': center,
      'c-section-heading--primary ': primary,
      'c-section-heading--small-spacing ': smallSpacing,
      'c-section-heading--subtitle ': subtitle && !smallSpacing,
      'c-section-heading--alternative ': alternative,
    },
    className
  ).trim()

  // tag containing a variable or condition
  const CustomTagSectionHeadingsLevel = `h${level}`

  function renderIfSectionHeadingsTitle() {
    if (children) {
      return (
        <CustomTagSectionHeadingsLevel className="c-section-heading__title">
          {alternative && titleSecondary ? (
            <>
              <span className="c-section-heading__title-text">{children}</span>
              <span className="c-section-heading__title-text u-heading-alternative">
                {titleSecondary}
                {superscript && <sup>{superscript}</sup>}
              </span>
            </>
          ) : (
            <span className="c-section-heading__title-text">
              {children}
              {superscript && <sup>{superscript}</sup>}
            </span>
          )}
        </CustomTagSectionHeadingsLevel>
      )
    }
    return null
  }

  return (
    <header className={headerClassNameList} data-testid={testId} {...otherProps}>
      {renderIfSectionHeadingsTitle()}
      {subtitle && <p className="c-section-heading__subtitle">{subtitle}</p>}
    </header>
  )
}

SectionHeading.propTypes = {
  level: PropTypes.string.isRequired, // Bronson template: 'section-headings-headline'.
  children: PropTypes.string, // Bronson template: 'section-headings-title'.
  primary: PropTypes.bool, // Convenience prop for c-section-heading--primary (Bronson template: 'section-headings-modifier').
  subtitle: PropTypes.node, // Bronson template: 'section-headings-text'.
  superscript: PropTypes.string, // Bronson template: 'section-headings-superscript'.
  smallSpacing: PropTypes.bool, // Convenience prop for c-section-heading--small-spacing (Bronson template: 'section-headings-modifier').
  center: PropTypes.bool, // Bronson template: 'section-headings-center'.
  alternative: PropTypes.bool, // Convenience prop for c-section-heading--alternative (Bronson template: 'section-headings-modifier').
  titleSecondary: PropTypes.string,
  className: PropTypes.string, // Bronson template: 'section-headings-modifier'.
  testId: PropTypes.string, // Added for data-testid attribute.
}
