import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

/*
 * Bronson Logo component.
 *
 * Generated React component. Do not modify.
 */
export function Logo({
  className,
  src,
  srcSet,
  breakpoint,
  title,
  alt,
  onClick,
  testId,
  ...otherProps /* in <a> tag */
}) {
  // generated
  const aClassNameList = classNames(
    {
      'c-logo ': true,
      'c-logo--has-image ': src,
    },
    className
  ).trim()

  // generated main result
  return (
    <a {...otherProps} data-testid={testId} className={aClassNameList} onClick={onClick} title={title}>
      {srcSet ? (
        <picture>
          <source srcSet={srcSet} media={`(max-width: ${breakpoint})`} />
          <img src={src} alt={alt} className="c-logo__image" />
        </picture>
      ) : (
        <img src={src} alt={alt} className="c-logo__image" />
      )}
    </a>
  )
}

Logo.propTypes = {
  className: PropTypes.string,
  small: PropTypes.bool, // Bronson template: 'logoSmall'.
  src: PropTypes.string, // Bronson template: 'path'.
  srcSet: PropTypes.string,
  breakpoint: PropTypes.string, // Bronson template: 'logoBreakpoint'.
  onClick: PropTypes.func, // Added for onClick attribute.
  testId: PropTypes.string, // Added for data-testid attribute.
}
