/* eslint-disable jsx-a11y/anchor-is-valid */
// TODO: @Bronson check usage of anchors!
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Button } from '../../Button/Button'

/*
 * Bronson NotificationCenterItem component.
 *
 * Generated React component. Do not modify.
 */
export function NotificationCenterItem({
  showReadStatus = true,
  statusReadLabel,
  statusUnreadLabel,
  showUnreadButton = true,
  markAsReadLabel,
  markAsUnreadLabel,
  close = true,
  closeLabel,
  children,
  timestamp,
  timestampText,
  datetime,
  testId,
  unread, // managed from NotificationCenter.notifications
  title, // managed from NotificationCenter.notifications
  onClose,
  onClick,
  onMarkReadUnread,
  ...otherProps /* in <article> tag */
}) {
  // generated
  const articleClassNameList = classNames({
    'c-notification-center__item ': true,
    'c-notification-center__item--read ': !unread,
  }).trim()

  // generated
  function renderIfShowReadStatus() {
    if (showReadStatus) {
      return (
        <span className="c-notification-center__item__status">
          <span className="c-notification-center__item__status-label">
            {unread ? statusReadLabel : statusUnreadLabel}
          </span>
        </span>
      )
    }
    return null
  }

  // generated
  function renderIfTitle() {
    if (title) {
      return (
        <h3 className="c-notification-center__item__heading">
          <a onClick={onClick} className="c-notification-center__item__heading-link">
            {title}
          </a>
        </h3>
      )
    }
    return null
  }

  const handleOnCLose = (event) => {
    event.nativeEvent.stopImmediatePropagation()
    onClose()
  }

  // generated
  function renderIfClose() {
    if (close) {
      return (
        <button className="c-notification-content-center__item__close" onClick={handleOnCLose} type="button">
          <span className="c-notification-content-center__item__close-label">{closeLabel}</span>
        </button>
      )
    }
    return null
  }

  // generated
  function renderIfText() {
    if (children) {
      return <div className="c-notification-center__item__body">{children}</div>
    }
    return null
  }

  // generated
  function renderIfTimestamp() {
    if (timestamp) {
      return (
        <time dateTime={datetime} className="c-notification-center__item__timestamp">
          {timestampText}
        </time>
      )
    }
    return null
  }

  // generated
  function renderIfShowUnreadButton() {
    if (showUnreadButton) {
      return (
        <Button small link className="c-notification-center__item__mark-read-toggle" onClick={onMarkReadUnread}>
          {unread ? markAsReadLabel : markAsUnreadLabel}
        </Button>
      )
    }
    return null
  }

  // generated main result
  return (
    <article {...otherProps} data-testid={testId} className={articleClassNameList}>
      {renderIfShowReadStatus()}
      {renderIfTitle()}
      {renderIfClose()}
      {renderIfText()}
      {renderIfTimestamp()}
      {renderIfShowUnreadButton()}
    </article>
  )
}

NotificationCenterItem.propTypes = {
  showReadStatus: PropTypes.bool, // Bronson template: 'show-read-status'.
  statusReadLabel: PropTypes.string, // Bronson template: 'status-read-label'.
  statusUnreadLabel: PropTypes.string, // Bronson template: 'status-unread-label'.
  title: PropTypes.node, // Bronson template: 'title', managed from NotificationCenter.notifications
  close: PropTypes.bool, // Bronson template: 'close'.
  onClose: PropTypes.func, // onClose callback (default logic managed)
  closeLabel: PropTypes.string, // Bronson template: 'close-label'.
  children: PropTypes.node, // Bronson template: 'text'.
  timestamp: PropTypes.bool, // Bronson template: 'timestamp'.
  timestampText: PropTypes.string, // Bronson template: 'timestamp.text'.
  datetime: PropTypes.string, // Bronson template: 'datetime'.
  showUnreadButton: PropTypes.bool, // Bronson template: 'show-unread-button'.
  include: PropTypes.string, // Bronson template: 'include'.
  unread: PropTypes.bool, // Bronson template: 'unread', managed from NotificationCenter.notifications
  markAsReadLabel: PropTypes.string, // label for "Mark as read" button
  markAsUnreadLabel: PropTypes.string, // label for "Mark as unread" button
  onMarkReadUnread: PropTypes.func, // callback when "read/unread" button is clicked (default logic managed)
  testId: PropTypes.string, // Added for data-testid attribute.
  onClick: PropTypes.func, // onClick callback (default logic managed)
}
