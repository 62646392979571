/* eslint-disable react/no-unused-prop-types */
import React, { useState } from 'react'
import Flatpickr from 'react-flatpickr'
import pl from 'flatpickr/dist/l10n/pl'
import it from 'flatpickr/dist/l10n/it'
import de from 'flatpickr/dist/l10n/de'
import cs from 'flatpickr/dist/l10n/cs'
import es from 'flatpickr/dist/l10n/es'
import fr from 'flatpickr/dist/l10n/fr'
import nl from 'flatpickr/dist/l10n/nl'
import pt from 'flatpickr/dist/l10n/pt'
import ja from 'flatpickr/dist/l10n/ja'
import sk from 'flatpickr/dist/l10n/sk'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import TextMaskInput from 'react-text-mask'

const disabledButtonStyle = { pointerEvents: 'none' }

/**
 * Bronson DatePicker component.
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-datepicker.html
 *
 * @param {string} [value] - Date value
 * @param {string} [placeholder] - Placeholder shown when no date is selected, @see https://www.npmjs.com/package/react-text-mask
 * @param {boolean} [showMask] - Show mask as placeholder, @see https://www.npmjs.com/package/react-text-mask
 * @param {string} [dateFormat='Y-m-d'] - Date format used by flatpickr, @see https://flatpickr.js.org/options/
 * @param {string} [locale] - Sets locale of date picker labels, one of ['pl', 'de', 'it', 'cs', 'es', 'fr', 'nl', 'pt', 'ja', 'sk'] (ISO-639-1 code)
 * @param {(string | Date)} [minDate] - Minimum date that can be selected (inclusive), @see https://flatpickr.js.org/options/
 * @param {(string | Date)} [maxDate] - Maximum date that can be selected (inclusive), @see https://flatpickr.js.org/options/
 * @param {boolean} [error=false] - Error state
 * @param {boolean} [disabled=false] - Disabled state
 * @param {boolean} [noBackground=true] - By default the addon has a background. To remove the background and
 *                                        only show the addon text and/or icon.
 * @param {Array} [disableDays] - Convenience prop to disable certain days, e.g. ["2020-01-30", "2020-02-21", "2020-03-08", new Date(2020, 4, 9) ], [{ from: "2025-04-01", to: "2025-05-01" }], @see https://flatpickr.js.org/examples/#disabling-dates
 * @param {boolean} [monthSelectorDropdown=false] - Month selector variant
 * @param {string} [name] - Name attribute of TextMaskInput
 * @param {('single' | 'multiple' | 'range')} [mode='single'] - Flatpickr mode, 'single', 'multiple' or 'range' values are selectable
 * @param {boolean} [guide] - Guided mode in TextMaskInput, @see https://github.com/text-mask/text-mask/blob/master/componentDocumentation.md#guide
 * @param {string} [className=''] - Additional CSS classes on Flatpickr element (div with class c-input)
 * @param {string} [datePickerClassName=''] - Additional CSS classes on input element of Flatpickr (input with class c-input__input)
 * @param {function} [onBlur] - onBlur handler
 * @param {function} [onChange] - onChange handler
 * @param {object} [flatpickrOptions] - Additional flatpickr options
 * @param {object} [flatpickrProps] - Additional props passed to Flatpickr React component
 *  @param {string} [testId] - data-testid attribute on table
 * @return {JSX.Element} - The ButtonContainer element.
 */
export function DatePicker({
  value: customValue,
  placeholder,
  showMask,
  dateFormat = 'Y-m-d',
  locale,
  minDate,
  maxDate,
  error = false,
  disabled = false,
  noBackground = true,
  disableDays,
  monthSelectorDropdown = false,
  name,
  mode = 'single',
  guide,
  className = '',
  datePickerClassName = '',
  onBlur,
  onChange: customOnChange,
  flatpickrOptions,
  flatpickrProps,
  testId,
  ...otherProps
}) {
  const [date, setDate] = useState(customValue || '')

  React.useEffect(() => {
    setDate(customValue)
  }, [customValue])

  const componentDatePickerWrapperClass = classNames('c-input', className).trim()

  const componentDatePickerClass = classNames(
    'c-input__input',
    {
      'is-error': error,
    },
    datePickerClassName
  ).trim()

  const componentDatePickerButtonClass = classNames('c-input__addon', {
    'c-input__addon--no-background': noBackground,
  }).trim()

  const componentDatePickerButtonIconClass = classNames('c-icon', {
    'c-icon--[semantic-calendar]': true,
  }).trim()

  function prepareMask() {
    const separator = dateFormat.substring(1, 2)
    const tokens = dateFormat.split(separator).map((item) => {
      return item === item.toUpperCase() ? [/\d/, /\d/, /\d/, /\d/] : [/\d/, /\d/]
    })

    return [].concat(tokens[0], separator, tokens[1], separator, tokens[2])
  }
  function preparePlaceholder() {
    const separator = dateFormat.substring(1, 2)
    const tokens = dateFormat.split(separator).map((item) => {
      return item === item.toUpperCase()
        ? `${item.toLowerCase()}${item.toLowerCase()}${item.toLowerCase()}${item.toLowerCase()}`
        : `${item}${item}`
    })
    return tokens.join(separator)
  }

  function setLocale() {
    switch (locale) {
      case 'pl':
        return pl.pl
      case 'de':
        return de.de
      case 'it':
        return it.it
      case 'cs':
        return cs.cs
      case 'es':
        return es.es
      case 'fr':
        return fr.fr
      case 'nl':
        return nl.nl
      case 'pt':
        return pt.pt
      case 'ja':
        return ja.ja
      case 'sk':
        return sk.sk
      default:
        return null
    }
  }

  function onChange(e) {
    const value = e.target === undefined ? e : e.target.value
    if ((!guide && value.length === preparePlaceholder().length) || (!!guide && value.indexOf('_') === -1)) {
      setDate(value)
      customOnChange(value)
    }
  }

  function renderInput() {
    return (
      <TextMaskInput
        {...otherProps}
        data-testid={testId}
        className={componentDatePickerClass}
        type="text"
        value={date}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        data-input
        mask={prepareMask()}
        showMask={!!showMask}
        guide={!!guide}
        placeholder={placeholder || preparePlaceholder()}
      />
    )
  }

  function renderIcon() {
    return <i className={componentDatePickerButtonIconClass} aria-hidden="true" role="img" />
  }

  function renderButton() {
    return (
      <button
        className={componentDatePickerButtonClass}
        type="button"
        tabIndex="-1"
        aria-label="toggle datepicker"
        data-toggle
        style={disabled ? disabledButtonStyle : undefined}
      >
        {renderIcon()}
      </button>
    )
  }

  return (
    <div className="c-datepicker js-datepicker">
      <Flatpickr
        className={componentDatePickerWrapperClass}
        {...flatpickrProps}
        value={date}
        onChange={(_, value) => onChange(value)}
        options={{
          wrap: true,
          allowInput: true,
          mode,
          locale: setLocale(),
          dateFormat,
          maxDate,
          minDate,
          disabled: disableDays,
          nextArrow: '<i class="c-icon c-icon--[semantic-forward] c-icon-–simple"></i>',
          prevArrow: '<i class="c-icon c-icon--[semantic-back] c-icon-–simple"></i>',
          monthSelectorType: monthSelectorDropdown ? 'dropdown' : 'static',
          ...flatpickrOptions,
        }}
      >
        {renderInput()}
        {renderButton()}
      </Flatpickr>
    </div>
  )
}

DatePicker.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  showMask: PropTypes.bool,
  dateFormat: PropTypes.string,
  locale: PropTypes.oneOf(['pl', 'de', 'it', 'cs', 'es', 'fr', 'nl', 'pt', 'ja', 'sk']), // ISO-639-1 code
  minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
  maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  noBackground: PropTypes.bool,
  disableDays: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date), PropTypes.object])), // convenience prop to disable certain days, e.g. ["2020-01-30", "2020-02-21", "2020-03-08", new Date(2020, 4, 9) ], [{ from: "2025-04-01", to: "2025-05-01" }]
  monthSelectorDropdown: PropTypes.bool,
  name: PropTypes.string,
  mode: PropTypes.string,
  guide: PropTypes.bool,
  className: PropTypes.string,
  datePickerClassName: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  flatpickrOptions: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  flatpickrProps: PropTypes.object,
  testId: PropTypes.string,
}
